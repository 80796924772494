import React, { useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { i18nConfig, useTranslation } from 'i18n/';
import hosts from 'services/apiClient/hosts';
import SEOGenerator from 'services/SEOGenerator';
import { cleanAsPath } from 'utils/fns';

import JsonLd from './JsonLd';

// TODO: Remove it after taking off the redirects from old website - OCT-20
const HIDEXDEFAULT_LIST = ['/business', '/tos', '/privacy-policy', '/cookies-policy', '/artist'];

const CommonSeo = ({
  pageTitle,
  pageDescription,
  metaTitle,
  metaDescription,
  metaImage,
  noindex,
  nofollow,
  breadcrumbs,
  schema,
  extraMeta,
}) => {
  const { asPath } = useRouter();
  const { lang, locale } = useTranslation();
  const cleanedAsPath = useMemo(() => cleanAsPath(asPath, lang), [asPath, lang]);
  // TODO: Remove it after taking off the redirects from old website - OCT-20
  const hideXDefaultMeta = useMemo(
    () => HIDEXDEFAULT_LIST.some(item => item === cleanedAsPath || cleanedAsPath.startsWith(item)),
    [cleanedAsPath]
  );

  const seo = useMemo(() => new SEOGenerator({ lang }), [lang]);
  const socialSchema = useMemo(() => seo.makeSocialSchema(), []);
  const breadcrumbsJsonLd = useMemo(() => seo.makeBreadcrumbsListJson(breadcrumbs), [breadcrumbs]);

  const MetaLanguages = useMemo(
    () =>
      i18nConfig.allLanguages.map(l => (
        <link
          rel="alternate"
          hrefLang={l}
          href={`${hosts.LEGACY_MONOLITH}/${l}${cleanedAsPath}`}
          key={l}
        />
      )),
    [cleanedAsPath]
  );

  const MetaLocales = useMemo(
    () =>
      i18nConfig.allLocales.map(
        l => l !== locale && <meta property="og:locale:alternate" content={l} key={l} />
      ),
    []
  );

  return (
    <>
      <Head>
        <link rel="canonical" href={`${hosts.LEGACY_MONOLITH}${asPath}`} />
        {MetaLanguages}
        {MetaLocales}
        {/* TODO: Remove it after taking off the redirects from old website - OCT-20 */}
        {!hideXDefaultMeta && (
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`${hosts.LEGACY_MONOLITH}${cleanedAsPath}`}
          />
        )}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta
          name="robots"
          content={`${noindex ? 'noindex' : 'index'}, ${nofollow ? 'nofollow' : 'follow'}`}
        />
        {/* Facebook card common meta tags */}
        <meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FB_APP_ID} key="fb:app_id" />
        {/* Twitter card common meta tags */}
        <meta name="twitter:card" content="summary_large_image" key="tw:card" />
        <meta name="twitter:site" content="@xceed_official" key="tw:site" />
        <meta name="twitter:creator" content="@xceed_official" key="tw:creator" />
        <meta name="twitter:title" content={metaTitle} key="tw:title" />
        <meta name="twitter:description" content={metaDescription} key="tw:desc" />
        <meta name="twitter:image" content={metaImage} key="tw:img" />
        {/* OpenGraph common meta tags */}
        <meta property="og:url" content={`${hosts.LEGACY_MONOLITH}${asPath}`} key="og:url" />
        <meta property="og:site_name" content={hosts.LEGACY_MONOLITH} key="og:name" />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:locale" content={locale} key="og:locale" />
        <meta property="og:title" content={metaTitle} key="og:title" />
        <meta property="og:description" content={metaDescription} key="og:desc" />
        <meta property="og:image" content={`${metaImage}?w=300&ar=1:1&fm=auto`} key="og:img" />
        <meta property="og:image:alt" content={metaTitle} key="og:img:alt" />
        <meta property="og:image:type" content="image/jpeg" key="og:img:type" />
        {/* Used temporarily to validate default site implementation */}
        <meta
          name="google-site-verification"
          content="kAUXS9j9TFKAeR6uMfmkI_DPQsW9D7VerOR17WpHiVE"
        />
        {extraMeta}
      </Head>
      {/* Structured data */}
      <JsonLd data={socialSchema} />
      {breadcrumbs.length ? <JsonLd data={breadcrumbsJsonLd} /> : null}
      {schema && <JsonLd data={schema} />}
    </>
  );
};

CommonSeo.propTypes = {
  /**
   *  A concise title for the related content.
   */
  pageTitle: PropTypes.string.isRequired,
  /**
   *  A description that concisely summarizes the content
   */
  pageDescription: PropTypes.string,
  /**
   *  A concise title for the meta tags.
   */
  metaTitle: PropTypes.string,
  /**
   *  A description that concisely summarizes the meta tag description.
   */
  metaDescription: PropTypes.string,
  /**
   *  An image url for meta tags
   */
  metaImage: PropTypes.string,
  /**
   *  Mark the page as noindex for the bots
   */
  noindex: PropTypes.bool,
  /**
   *  Indicate to the bot not to follow the links on the page
   */
  nofollow: PropTypes.bool,
  /**
   *  The json schema for the page
   */
  schema: PropTypes.objectOf(PropTypes.unknown),
  /**
   *  The breadcrumb items for the structured data script
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  /**
   *  Extra meta node to inject in the head
   */
  extraMeta: PropTypes.node,
};

CommonSeo.defaultProps = {
  metaTitle: '',
  metaDescription: '',
  pageDescription: '',
  metaImage: '',
  noindex: false,
  nofollow: false,
  schema: null,
  breadcrumbs: [],
  extraMeta: null,
};

export default React.memo(CommonSeo);
