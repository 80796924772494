import formatISO from 'date-fns/formatISO';

import hosts from './apiClient/hosts';

const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

class SEOGenerator {
  constructor({ lang = 'en' } = {}) {
    this.lang = lang === 'en' ? '' : `/${lang}`;
    this.host = hosts.LEGACY_MONOLITH;
    this.baseUrl = `${this.host}${this.lang}`;
  }

  makeEventJson = ({ event, venue, lineUp, offers }) => {
    const { legacyId, name, slug, about, agePolicy, coverUrl, startingTime, endingTime } = event;

    const url = `${this.baseUrl}/${venue?.city?.slug}/event/${slug}--${legacyId}`;
    const startingDate = new Date(startingTime * 1000);
    const endingDate = new Date(endingTime * 1000);
    const [hh, mm, ss] = formatISO(endingDate - startingDate, { representation: 'time' }).split(
      ':'
    );
    const formattedAbout = about ? about.replace(/\r?\n|\r/g, '') : null;
    const formattedVenueAbout = venue.about ? venue.about.replace(/\r?\n|\r/g, ' ') : null;

    const performer = lineUp && lineUp.map(this.makePerformerJson);

    return {
      '@context': 'https://schema.org',
      '@type': 'MusicEvent',
      name,
      url,
      startDate: startingDate.toISOString(),
      endDate: endingDate.toISOString(),
      ...(performer?.length && { performer }),
      duration: `T${hh}H${mm}M${ss}S`,
      ...(agePolicy?.name && { typicalAgeRange: agePolicy.name }),
      ...(formattedAbout && { description: formattedAbout }),
      organizer: this.makeOrganizer(venue),
      location: {
        '@type': 'NightClub',
        url: `${this.baseUrl}/${venue?.city?.slug}/club/${venue.slug}`,
        name: venue.name,
        ...(venue.coverUrl && { image: venue.coverUrl }),
        ...(formattedVenueAbout && { description: formattedVenueAbout }),
        priceRange: '$$',
        address: {
          '@type': 'PostalAddress',
          ...(venue?.address && { streetAddress: venue.address }),
          ...(venue?.city?.name && { addressLocality: venue.city.name }),
          ...(venue?.city?.country?.isoCode && { addressCountry: venue.city.country.isoCode }),
        },
        geo: {
          '@type': 'GeoCoordinates',
          ...(venue?.coordinates && {
            latitude: venue?.coordinates?.latitude,
            longitude: venue?.coordinates?.longitude,
          }),
        },
        openingHoursSpecification: [
          {
            '@type': 'OpeningHoursSpecification',
            validFrom: startingDate.toISOString().split('T')[0],
            validThrough: endingDate.toISOString().split('T')[0],
            opens: startingDate.toISOString().split('T')[1],
            closes: endingDate.toISOString().split('T')[1],
          },
        ],
      },
      image: [coverUrl],
      ...(performer?.length && { performer }),
      ...(offers?.length && { offers: offers.map(offer => this.makeOfferJson(offer, url)) }),
      eventAttendanceMode: 'OfflineEventAttendanceMode',
    };
  };

  makeBarJson = ({ bar }) => {
    const {
      slug,
      name,
      about,
      logoUrl,
      coverUrl,
      address,
      city,
      phone,
      priceLevel,
      coordinates,
      reviews,
      openingHours,
    } = bar;
    const url = `${this.baseUrl}/${city.slug}/bar/${slug}`;
    const formattedBarAbout = about ? about.replace(/\r?\n|\r/g, ' ') : null;
    return {
      '@context': 'https://schema.org',
      '@type': 'BarOrPub',
      url,
      logo: logoUrl,
      image: coverUrl,
      name,
      ...(formattedBarAbout && { description: formattedBarAbout }),
      priceRange: '$'.repeat(priceLevel),
      address: {
        '@type': 'PostalAddress',
        ...(address && { streetAddress: address }),
        ...(city?.name && { addressLocality: city.name }),
        ...(city?.country?.isoCode && { addressCountry: city.country.isoCode }),
      },
      telephone: phone,
      geo: {
        '@type': 'GeoCoordinates',
        ...(coordinates && {
          latitude: coordinates?.latitude,
          longitude: coordinates?.longitude,
        }),
      },
      ...(!!reviews?.length && {
        aggregateRating: this.makeAggregateRatingJson(reviews),
        review: reviews.map(this.makeReviewJson),
      }),
      ...(!!openingHours?.length && {
        openingHoursSpecification: openingHours.map(this.makeOpeningHoursJson),
      }),
    };
  };

  makeRestaurantJson = ({ restaurant }) => {
    const {
      slug,
      name,
      about,
      logoUrl,
      coverUrl,
      address,
      city,
      phone,
      priceLevel,
      coordinates,
      reviews,
      openingHours,
    } = restaurant;
    const url = `${this.baseUrl}/${city.slug}/restaurant/${slug}`;
    const formattedBarAbout = about ? about.replace(/\r?\n|\r/g, ' ') : null;
    return {
      '@context': 'https://schema.org',
      '@type': 'Restaurant',
      url,
      logo: logoUrl,
      image: coverUrl,
      name,
      ...(formattedBarAbout && { description: formattedBarAbout }),
      priceRange: '$'.repeat(priceLevel),
      address: {
        '@type': 'PostalAddress',
        ...(address && { streetAddress: address }),
        ...(city?.name && { addressLocality: city.name }),
        ...(city?.country?.isoCode && { addressCountry: city.country.isoCode }),
      },
      telephone: phone,
      geo: {
        '@type': 'GeoCoordinates',
        ...(coordinates && {
          latitude: coordinates?.latitude,
          longitude: coordinates?.longitude,
        }),
      },
      ...(!!reviews?.length && {
        aggregateRating: this.makeAggregateRatingJson(reviews),
        review: reviews.map(this.makeReviewJson),
      }),
      ...(!!openingHours?.length && {
        openingHoursSpecification: openingHours.map(this.makeOpeningHoursJson),
      }),
    };
  };

  makeClubJson = ({ club, events }) => {
    const { slug, name, about, logoUrl, coverUrl, address, city, reviews } = club;
    const url = `${this.baseUrl}/${city.slug}/club/${slug}`;
    const formattedClubAbout = about ? about.replace(/\r?\n|\r/g, ' ') : null;
    const eventsJsonLd = events?.map(event =>
      this.makeEventJson({ event, venue: club, offers: event.offers, lineUp: event.lineUp })
    );

    return {
      '@context': 'https://schema.org',
      '@type': 'NightClub',
      url,
      logo: logoUrl,
      image: coverUrl,
      name,
      ...(formattedClubAbout && { description: formattedClubAbout }),
      priceRange: '$$',
      address: {
        '@type': 'PostalAddress',
        ...(address && { streetAddress: address }),
        ...(city?.name && { addressLocality: city.name }),
        ...(city?.country?.isoCode && { addressCountry: city.country.isoCode }),
      },
      geo: {
        '@type': 'GeoCoordinates',
        ...(club?.coordinates && {
          latitude: club?.coordinates?.latitude,
          longitude: club?.coordinates?.longitude,
        }),
      },
      ...(eventsJsonLd && { event: eventsJsonLd }),
      ...(!!reviews?.length && {
        aggregateRating: this.makeAggregateRatingJson(reviews),
        review: reviews.map(this.makeReviewJson),
      }),
    };
  };

  makeArtistJson({ artist, events }) {
    const { name, slug, coverUrl, description, musicGenres } = artist;
    const url = `${this.baseUrl}/artist/${slug}`;
    const formattedArtistAbout = description ? description.replace(/\r?\n|\r/g, ' ') : null;

    return {
      '@context': 'https://schema.org',
      '@type': 'MusicGroup',
      name,
      url,
      image: [coverUrl],
      ...(formattedArtistAbout && { description: formattedArtistAbout }),
      ...(musicGenres[0] && { genre: musicGenres[0].name }),
      ...(events?.length && {
        event: events.slice(0, 3).map(event =>
          this.makeEventJson({
            event,
            venue: event.venue,
            offers: event.offers,
            lineUp: [artist],
          })
        ),
      }),
    };
  }

  makeOfferJson = (offer, eventUrl) => {
    const { price, name, startingTime, closingTime, settings, currency: priceCurrency } = offer;
    const availabilityStarts = new Date(startingTime * 1000).toISOString();
    const availabilityEnds = new Date(closingTime * 1000).toISOString();
    const availability = `http://schema.org/${settings.isSoldOut ? 'SoldOut' : 'InStock'}`;

    return {
      '@type': 'Offer',
      name,
      price,
      priceCurrency,
      availability,
      availabilityStarts,
      availabilityEnds,
      validFrom: availabilityStarts,
      validThrough: availabilityEnds,
      url: eventUrl,
      // TODO: change them with proper checkout information if available
      acceptedPaymentMethod: [
        'http://purl.org/goodrelations/v1#MasterCard',
        'http://purl.org/goodrelations/v1#AmericanExpress',
        'http://purl.org/goodrelations/v1#VISA',
      ],
    };
  };

  makePerformerJson = ({ name, slug, musicGenres }) => {
    return {
      '@type': 'MusicGroup',
      name,
      sameAs: '',
      url: `${this.host}${this.lang}/artist/${slug}`,
      ...(musicGenres && { genre: musicGenres.length ? musicGenres[0].name : 'none' }),
    };
  };

  makeBreadcrumbsListJson = breadcrumbs => {
    return {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      ...(breadcrumbs?.length && { itemListElement: breadcrumbs.map(this.makeBreadcrumbsJson) }),
    };
  };

  makeBreadcrumbsJson = (item, pos) => {
    return {
      '@type': 'ListItem',
      position: pos + 1,
      item: {
        '@id': `${hosts.LEGACY_MONOLITH}/${item.url}`,
        name: item.name,
      },
    };
  };

  makeWebsiteJson = ({ metaDescription }) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Xceed',
      description: metaDescription,
      url: hosts.LEGACY_MONOLITH,
    };
  };

  makeCityJson = ({ events, name, geo }) => {
    const eventsJsonLd = events?.map(event =>
      this.makeEventJson({
        event,
        venue: event.venue,
        offers: event.offers,
        lineUp: event.lineUp,
      })
    );
    const url = `${this.baseUrl}/${geo}`;

    return {
      '@context': 'http://schema.org',
      '@type': 'TouristDestination',
      name,
      description: 'Guide',
      url,
      touristType: {
        '@type': 'Audience',
        audienceType: ['young people', 'musicians'],
      },
      ...(eventsJsonLd && { event: eventsJsonLd }),
    };
  };

  makeSocialSchema = () => {
    return {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      url: hosts.LEGACY_MONOLITH,
      name: 'Xceed',
      logo: {
        url: `${hosts.LEGACY_MONOLITH}/static/xceed-logo.svg`,
        width: '192',
        '@context': 'http://schema.org',
        '@type': 'ImageObject',
      },
      sameAs: [
        'https://www.instagram.com/xceed_official/',
        'https://www.facebook.com/Xceed/',
        'https://twitter.com/xceed_official',
        'https://www.youtube.com/c/xceedmepartytonight',
        'https://vimeo.com/xceed',
        'https://www.linkedin.com/company/xceed-org-',
        'https://soundcloud.com/xceed-music',
        'https://open.spotify.com/user/xceedmusic',
        'https://www.tiktok.com/@xceed_official',
      ],
    };
  };

  makeReviewJson = review => {
    const { author, rating, description, publishedAt } = review;
    const datePublished = new Date(publishedAt * 1000).toJSON();
    return {
      '@type': 'Review',
      description,
      datePublished,
      author: `${author.firstName} ${author.lastName}`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: rating,
        worstRating: '1',
      },
    };
  };

  makeAggregateRatingJson = reviews => {
    const reviewCount = reviews.length;
    const ratingValue =
      reviews.reduce((tot, rev) => {
        return tot + rev.rating;
      }, 0) / reviewCount;

    return {
      '@type': 'AggregateRating',
      ratingValue,
      reviewCount,
    };
  };

  makeOpeningHoursJson = openingHours => {
    const { open, close } = openingHours;
    return {
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: [WEEK_DAYS[open.day]],
      opens: open.time,
      closes: close.time,
    };
  };

  makeOrganizer = venue => {
    return {
      '@type': 'Organization',
      name: venue.name,
      url: `${this.baseUrl}/${venue?.city?.slug}/club/${venue.slug}`,
    };
  };
}

export default SEOGenerator;
